import React from 'react';
import { FaFacebook } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { ImInstagram } from "react-icons/im";
import './navStyle.scss';

import './style.scss';
export default function Navbar() {
	const [isActive, setisActive] = React.useState(false)
  
	return (
	  <nav className='navbar' role='navigation' aria-label='main navigation'>
		<div className='navbar-brand'>
		  <a href='/' className='navbar-item navlogo'>
			ShabdOS
		  </a>
  
		  <a
			onClick={() => {
			  setisActive(!isActive)
			}}
			role='button'
			className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
			aria-label='menu'
			aria-expanded='false'
			data-target='navbarBasicExample'
		  >
			<span aria-hidden='true'></span>
			<span aria-hidden='true'></span>
			<span aria-hidden='true'></span>
		  </a>
		</div>
		<div id='navbarBasicExample' className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
		  <div className='navbar-end'>
			<div className='navbar-item'>
			  	<a href='/' className='navbar-item'>Home</a>
			  	<a href='/about' className='navbar-item'>About Me</a>
			  	<a href='/event' className='navbar-item'>Events</a>
				  <div class="navbar-item has-dropdown is-hoverable">
        			<a class="navbar-link" href="">Albums</a>
        			<div class="navbar-dropdown is-boxed">
          				<a class="navbar-item" href="">Playlist</a>
          				<a class="navbar-item" href="">Most Listen</a>
					</div>
				  </div>
				  <FaFacebook size="1.8em"  className="socialicon"/>
				  <GrTwitter size="1.8em"   className="socialicon"/>
				  <ImInstagram size="1.8em" className="socialicon"/>
				  <button class="button shopbutton">Shop Albums</button>
			</div>
		  </div>
		</div>
	  </nav>
	)
  }