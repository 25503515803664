import React from 'react'

import Navbar from '../components/navbar';
import Hero from '../components/eventHero';
import Timeline from '../components/eventTimeline';
import Footer from '../components/footer';

export default function eventpage() {
    return(
        <div>
            <Navbar />
            <Hero />
            <Timeline />
            <Footer />
        </div>
    )
}