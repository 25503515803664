import React from 'react';
import './eventHero.scss';
import { ImPlay } from "react-icons/im";

export default function herosection(){
    return(
        <section class="section is-medium eventherosection">
            <div className="column is-12">
                <h1 className="eventherohead">My next <strong className="eventTime">Live</strong> Event is coming soon!</h1>
            </div>
            <div className="column is-12">
                <h3 className="eventsunhead">Watch my gallery live stream on Saturday.</h3>
                <button className="playbtn"><ImPlay size="5em" color="red"/></button>
            </div>
        </section>
    );
}