import React from 'react';
import "./footerstyle.scss";
import { FaFacebook } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { ImInstagram } from "react-icons/im";




export default function ImageCard() {
  return (
    <section class="section level footersection">
      <div className="column is-6 footercomponent">
        <p className="footertext">ShabdOS</p>
        <p className="footercopyright">© Copyright YourSite - All Rights Reserved</p>
      </div>
      <div className="column is-6 footercomponent">
        <FaFacebook size="2em" color="#4267B2" className="socialicon"/>
        <GrTwitter size="2em" color="#1DA1F2"  className="socialicon"/>
        <ImInstagram size="2em" color="#FD1D1D" className="socialicon"/><br/>
        <ul className="list">
          <li className="footerlink list-item">Terms</li>
          <li className="footerlink list-item">Privacy Policy</li>
          <li className="footerlink list-item">Contact</li>
        </ul>
      </div>
    </section>
    
    )}